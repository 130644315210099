import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Images
import WaFdMobileApp from "../../../images/wafd-mobile-app.gif";
import KrakenDarkLogo from "../../../images/third-party/kraken-wordmark-dark.svg"

// components
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../../components/hero/hero-chevron";
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import VideoModal from "../../../components/video-modal/video-modal";
import List from "../../../components/custom-widgets/list";
import Icon from "../../../components/custom-widgets/icon";
import FaqAccordion from "../../../components/faq/faq-accordion";

import OnlineEnrollmentModal from "../../../components/custom-widgets/online-enroll-modal";

import Button from "../../../components/custom-widgets/button";
import PersonalizationMessageBar from "../../../components/notifications/personalization-message-bar";
import MobileAppDownloadBtn from "../../../components/mobile-online-banking/mobile-app-download-btn";

// Styles
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

const OnlineBanking = () => {
  const title = "Online & Mobile Banking - Manage Your Account Online",
    description =
      "Check out WaFd Bank's (Washington Federal Bank), online and mobile banking where you can manage your account, pay bills, deposit a check and transfer funds 24/7.";

  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/online-banking/hero-online-banking-091923-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/online-banking/hero-online-banking-091923-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/online-banking/hero-online-banking-091923-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/online-banking/hero-online-banking-091923-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/online-banking/hero-online-banking-091923-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/online-banking/hero-online-banking-091923-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/online-banking/hero-online-banking-091923-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const [showVideo, setShowVideo] = useState(false);
  const handleCloseVideo = () => setShowVideo(false);
  const handleShowVideo = () => {
    setShowVideo(true);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const enrollButtonData = {
    id: "enroll-btn",
    type: "button",
    class: "w-100 w-sm-auto btn-primary mb-3",
    text: "Enroll in Online Banking",
    showIcon: false,
    onClick: handleShow
  };

  const heroChevronData = {
    id: "online-banking-hero",
    ...getHeroImgVariables(imgData),
    altText: "Woman walking outside while on a mobile phone.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Access online banking when you're on the go"
          }
        },
        {
          id: 2,
          button: {
            id: "mobile-banking-video-cta",
            containerClass: "btn-container-long",
            class: "btn-white btn-long",
            type: "button",
            text: "Welcome to online banking",
            onClick: handleShowVideo,
            icon: { name: "video", lib: "fas", class: "float-right mt-1 ml-0 ml-sm-1 ml-md-3" }
          }
        }
      ]
    }
  };

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/online-banking"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-online-banking-091923.jpg"
      }
    ]
  };

  const enrollModal = {
    id: "online-enroll-modal",
    handleClose,
    show
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Mobile & Online Banking"
    }
  ];

  const IconHeadingSection = (props) => (
    <div className="d-flex align-items-center justify-content-between mb-3">
      <h3 className="text-success mb-0" dangerouslySetInnerHTML={{ __html: props.title }} />
      <img src={props.iconSrc} alt="" className="ml-3" />
    </div>
  );

  const PlaystoreIconsSection = () => (
    <>
      <div className="col-auto text-center my-3">
        <MobileAppDownloadBtn
          idName="apple-store"
          storeBtn="apple"
          url="https://apps.apple.com/us/app/wafd-bank/id1054549841"
        />
        <div className="text-sm text-gray-60 text-center">For Apple devices</div>
      </div>
      <div className="col-auto text-center my-3">
        <MobileAppDownloadBtn
          idName="google-play-store"
          storeBtn="google"
          url="https://play.google.com/store/apps/details?id=com.washingtonfederal.mobilebanking&amp;hl=en_US"
        />
        <div className="text-sm text-gray-60 text-center">For Android devices</div>
      </div>
    </>
  );

  const manageAccountsListData = {
    accountName: "manage-accounts-list",
    items: [
      {
        id: 1,
        text: "Check your balance, view transactions and transfer money"
      },
      {
        id: 2,
        text: "Connect accounts from any bank to see everything in one place"
      },
      {
        id: 3,
        text: "Receive optional electronic statements"
      }
    ]
  };

  const payBillsListData = {
    accountName: "pay-bills-list",
    items: [
      {
        id: 1,
        text: "Pick the date your payment is due and we will send the funds, so they always arrive by the due date"
      },
      {
        id: 2,
        text: "Set payment reminders and schedule automatic recurring payments"
      },
      {
        id: 3,
        text: "Pay multiple bills at once"
      }
    ]
  };

  const depositsListData = {
    accountName: "make-deposits-list",
    items: [
      {
        id: 1,
        text: "Quickly deposit checks from your tablet or smart phone"
      },
      {
        id: 2,
        text: "Set up direct deposit"
      }
    ]
  };

  const bankSafelyListData = {
    accountName: "bank-safely-list",
    items: [
      {
        id: 1,
        text: "Lock or unlock debit cards"
      },
      {
        id: 2,
        text: "Place stop payments"
      },
      {
        id: 3,
        text: "Enjoy fraud protection"
      },
      {
        id: 4,
        text: "Use credit file monitoring"
      },
      {
        id: 5,
        text: "You're FDIC Insured"
      }
    ]
  };

  const faqAccordionData = {
    sectionClass: "pt-0",
    id: "online-mobile-banking-faq-accordion",
    seeMoreFaqsLink: "/personal-banking/online-banking/faq#online-and-mobile-banking-faq-sections",
    seeMoreFaqsId: "online-mobile-banking-faq-see-more-faqs-link",
    seeMoreFaqsText: "All Mobile &amp; Online Banking FAQs",
    faqCategoryNames: ["Online Banking"]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <PersonalizationMessageBar />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <VideoModal
        show={showVideo}
        handleClose={handleCloseVideo}
        {...{
          id: "getting-started-mobile-banking",
          VimeoID: "651716485",
          Title: "Getting Started With Mobile Banking"
        }}
      />
      {show && <OnlineEnrollmentModal {...enrollModal} />}
      <section className="container" id="mobile-app-section">
        <h1>Convenient and secure online banking</h1>
        <div className="row">
          <div className="col-md-5 col-lg-4">
            <h3 className="text-success">Manage your money 24/7 with WaFd Online Banking and mobile app</h3>
            <p className="font-weight-bold">Already have a WaFd Account?</p>
            <Button {...enrollButtonData} />
            <div className="row mt-4">
              <div className="col-8 mx-auto mx-md-0 text-center">
                <StaticImage
                  src="../../../images/wafd-mobile-app-qr-code.png"
                  alt="WaFd App QR Code"
                  placeholder="blurred"
                  quality="100"
                  className="mb-3"
                />
                <p className="text-success font-weight-bold">Scan the QR code to download the mobile app</p>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-lg-4 text-center">
            <img src={WaFdMobileApp} className="w-100 w-sm-50 w-md-100" alt="WaFd Bank Mobile App home screen" />
            {/* <StaticImage
              src="../../../images/wafd-mobile-app-bg-green.jpg"
              alt="WaFd Bank Mobile App home screen"
              placeholder="blurred"
              quality="100"
              className="w-sm-50 w-md-100"
            /> */}
            <div className="d-lg-none justify-content-center row">
              <PlaystoreIconsSection />
            </div>
          </div>
          <div className="col-lg-4 d-none d-lg-flex d-lg-flex flex-column align-items-center justify-content-end">
            <PlaystoreIconsSection />
          </div>
        </div>
      </section>
      <section className="kraken-primary-bg">
        <div className="container">
          <div className="row">
            <div className="col-sm-auto text-center">
              <img src={KrakenDarkLogo} className="mb-3" alt="Seattle Kraken" height={63} />
            </div>
            <div className="col">
              <h3 className="text-kraken-ice-blue">Go Dark or Kraken mode on your WaFd App!</h3>
              <div className="text-white">
                Download or update your app, then go to Menu &gt; Settings &gt; Change Theme
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container section-padding-adjusted">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
          {/* Manage Accounts */}
          <div className="col mb-4 card border-0" id="manage-accounts">
            <div className="bg-light card-body border-radius-12">
              <IconHeadingSection title="Manage Accounts" iconSrc="../../../images/icons/icon-manage-accounts.svg" />
              <List {...manageAccountsListData} />
              <button onClick={handleShow} className="btn-anchor-link" id="online-banking-enroll-link">
                Enroll in Online Banking
                <Icon name="arrow-right" class="ml-2" />
              </button>
            </div>
          </div>
          {/* Pay Bills */}
          <div className="col mb-4 card border-0" id="pay-bills">
            <div className="bg-light card-body border-radius-12">
              <IconHeadingSection title="Pay Bills Online" iconSrc="../../../images/icons/icon-pay-bils.svg" />
              <List {...payBillsListData} />
            </div>
          </div>
          {/* Use Zelle */}
          <div className="col mb-4 card border-0" id="zelle">
            <div className="bg-light card-body border-radius-12">
              <IconHeadingSection title="Use Zelle<sup>&reg;</sup>" iconSrc="../../../images/icons/icon-zelle.svg" />
              <p>
                Introducing <em>Zelle</em>&mdash;a fast, safe and easy way to send money to your friends, family and
                associates in minutes, right from WaFd Bank's mobile app.
              </p>
              <Link
                to="/personal-banking/mobile-banking-app/zelle"
                id="zelle-page-link"
                className="text-decoration-none"
              >
                Learn more about <em>Zelle</em>
                <Icon name="arrow-right" class="ml-2" />
              </Link>
            </div>
          </div>
          {/* Make Deposits */}
          <div className="col mb-4 card border-0" id="make-deposits">
            <div className="bg-light card-body border-radius-12">
              <IconHeadingSection title="Make Deposits" iconSrc="../../../images/icons/icon-make-deposits.svg" />
              <List {...depositsListData} />
              <button onClick={handleShowVideo} className="btn-anchor-link" id="online-banking-video-link">
                How do I make a mobile deposit?
                <Icon name="video" lib="far" class="ml-2" />
              </button>
            </div>
          </div>
          {/* Pay with a Tap */}
          <div className="col mb-4 card border-0" id="pay-with-a-tap">
            <div className="bg-light card-body border-radius-12">
              <IconHeadingSection title="Pay with a Tap" iconSrc="../../../images/icons/icon-mobile-pay.svg" />
              <p>
                Tap-to-pay with your debit card or use Mobile Pay. Both are fast, simple, and secure ways to pay in
                millions of places. Use Mobile Pay to make a purchase right from your mobile device using your Digital
                Wallet.
              </p>
              <p>
                Your WaFd Bank{" "}
                <Link id="debit-cards-page-link" to="/personal-banking/debit-cards">
                  debit card
                </Link>{" "}
                and{" "}
                <Link to="/personal-banking/credit-cards" id="credit-cards-page-link">
                  credit card
                </Link>{" "}
                is Digital Wallet-enabled for Apple Pay, Google Pay, and Samsung Pay.
              </p>
              <Link
                to="/personal-banking/debit-cards/digital-debit-card"
                id="digital-debit-card-page-link"
                className="text-decoration-none"
              >
                Learn more about Digital Wallet
                <Icon name="arrow-right" class="ml-2" />
              </Link>
            </div>
          </div>
          {/* Bank Safely */}
          <div className="col mb-4 card border-0" id="bank-safely">
            <div className="bg-light card-body border-radius-12">
              <IconHeadingSection title="Bank Safely" iconSrc="../../../images/icons/icon-bank-safely.svg" />
              <List {...bankSafelyListData} />
              <Link to="/banking-privacy-security" id="privacy-security-page-link" className="text-decoration-none">
                WaFd Privacy and Security
                <Icon name="arrow-right" class="ml-2" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <FaqAccordion {...faqAccordionData} />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default OnlineBanking;

